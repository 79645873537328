<template>

  <div class="mt-12 relative flex justify-center items-center w-full">

    <div class="m-4 z-10 flex flex-col rounded-xl il-shadow bg-white max-w-md">

      <div class="text-xl p-4 bg-il-purple text-white text-center uppercase rounded-t-xl">
        Register
      </div>

      <div class="p-4 text-sm">
        <div class="text-gray-500">
          Name
        </div>
        <div>
          <input
          @keyup.enter="register"
          v-model="name"
          type="text" class="text-lg text-il-purple w-full py-2 border-b border-gray-300 focus:outline-none focus:border-ziqe">
        </div>
      </div>

      <div class="p-4 text-sm">
        <div class="text-gray-500">
          Email
        </div>
        <div>
          <input
          @keyup.enter="register"
          v-model="email"
          type="text" class="text-lg text-il-purple w-full py-2 border-b border-gray-300 focus:outline-none focus:border-ziqe">
        </div>
      </div>

      <div class="p-4 text-sm">
        <div class="text-gray-500">
          Password
        </div>
        <div>
          <input
          @keyup.enter="register"
          v-model="password"
          type="password" class="text-lg text-il-purple w-full py-2 border-b border-gray-300 focus:outline-none focus:border-ziqe">
        </div>
      </div>

      <div class="p-4 text-sm">
        <div class="text-gray-500">
          Confirm Password
        </div>
        <div>
          <input
          @keyup.enter="register"
          v-model="passwordConfirmation"
          type="password" class="text-lg text-il-purple w-full py-2 border-b border-gray-300 focus:outline-none focus:border-ziqe">
        </div>
      </div>

      <div class="flex p-4 items-top">
        <input id="terms-accept" v-model="termsAccepted" type="checkbox" class="mt-1 h-4 w-4 text-il-purple">
        <div class="ml-2">
          <label for="terms-accept" class="font-medium text-gray-700">I read and accept the Terms and and Conditions and Privacy Policy</label>
        </div>
      </div>

      <div class="flex justify-center items-center pb-4">
        <div
          class="transition-all ease-in-out duration-200 bg-il-purple text-white py-2 uppercase rounded-xl text-center"
          :class="registerLoading || loginSuccess ? 'w-100px' : 'cursor-pointer w-200px'"
          @click="register"
          >
          <span v-if="loginSuccess">
            <i class="fas fa-check"></i>
          </span>
          <span v-else-if="registerLoading">
            <i class="fas fa-circle-notch fa-spin"></i>
          </span>
          <span v-else>
            Submit
          </span>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import authRequests from '../../helpers/authRequests';

  export default {
    name: 'home',
    mounted() {

    },
    data() {
      return {
        name: null,
        email: null,
        password: null,
        passwordConfirmation: null,
        registerLoading: false,
        loginSuccess: false,
        termsAccepted: false
      }
    },
    components: {

    },
    methods: {
      register() {
        if(!this.registerLoading) {
          this.registerLoading = true;
          authRequests.register(this.name, this.email, this.password, this.passwordConfirmation, this.termsAccepted)
          .then((response) => {
            // Registration Complete. Log the user in.
            authRequests.login(this.email, this.password)
            .then((response) => {
              // Login successful
              this.loginUserX(response.data.user);
              this.registerLoading = false;
              this.loginSuccess = true;
              setTimeout(() => {
                this.$router.push({ name: 'profile' })
              }, 1000);
            }).catch(error => {
              // Login failed
              this.registerLoading = false;
              console.log(error);
              this.$toast.error('Something went wrong while Logging you in.<br />Try to Login in manually.', {
                position: "bottom"
              });
            });
          }).catch(error => {
            // Registration Failed
            this.registerLoading = false;
            const errors = error.response.data.errors;
            let errorString = '';
            let newLine = '';
            for(const err in errors) {
              errorString += newLine + errors[err][0];
              newLine = '<br />';
            }

            this.$toast.error(errorString, {
              position: "bottom"
            });
          });
        }
      },
      ...mapMutations([
        'loginUserX'
      ])
    }
  }
</script>

<style scoped>
</style>